import { Outlet, RouterProvider, RootRoute as Root, Router } from "@tanstack/react-router";
import { LocationsRoute } from "./routes/LocationsRoute";
import { MainRoute } from "./routes/MainRoute";
import { ProjectsRoute } from "./routes/ProjectsRoute";
import { SigninRoute } from "./routes/SigninRoute";
import { ConfirmProjectsRoute } from "./routes/ConfirmProjectsRoute";
import { AssignProjectRoute } from "./routes/AssignProjectRoute";
import { AssignmentDashboardRoute } from "./routes/AssignmentDashboardRoute";
import { AssignmentCaptureRoute } from "./routes/AssignmentCapture/AssignmentCaptureRoute";
import { TestRoute } from "./routes/TestRoute";

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof ApiaryRouter
    }
}

export const RootRoute = new Root({
    component: () => (
        <>
            <Outlet />
        </>
    ),
})

const routeTree = RootRoute.addChildren([
    TestRoute,
    MainRoute,
    SigninRoute,
    ProjectsRoute,
    LocationsRoute,
    ConfirmProjectsRoute,
    AssignProjectRoute,
    AssignmentDashboardRoute,
    AssignmentCaptureRoute
]);

export const ApiaryRouter = new Router({
    routeTree
});
export const ApiaryComponent = () => <RouterProvider router={ApiaryRouter} />