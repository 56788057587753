import { Route, useNavigate } from "@tanstack/react-router"
import { RootRoute } from "../Router"
import { LocationsRoute } from "./LocationsRoute"
import { AssignmentDashboardRoute } from "./AssignmentDashboardRoute";

const MainScreen = () => {
    const navigate = useNavigate();
    return <div className='card'>
        <div className="card-body">
            <div>
                <h5 className="card-title">Welcome to The Apiary!</h5>
            </div>
            <div className="card-text">
                <div className='card' onClick={() => navigate({ to: LocationsRoute.to })}>
                    <div className="card-body">
                        <div className="card-text">
                            Find projects near me
                        </div>
                    </div>
                </div >
                <div className='card' onClick={() => navigate({ to: AssignmentDashboardRoute.to })}>
                    <div className="card-body">
                        <div className="card-text">
                            Assignment dashboard
                        </div>
                    </div>
                </div >
            </div>
        </div>
    </div >
}

export const MainRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/',
    component: MainScreen
})