import { Suspense } from "react"
import { SelectableProjectCard } from "../components/ui/Projects/ProjectCard";
import { Await, Route, defer } from "@tanstack/react-router";
import { Client } from "../Utils";
import { LoadingScreen } from "apiary-ui";
import { RootRoute } from "../Router";
import { ProjectToast } from "../components/ui/Projects/ProjectToast";

const ProjectsScreen = () => {
    const { data } = ProjectsRoute.useLoaderData();

    return <Suspense fallback={<LoadingScreen />}>
        <Await promise={data}>
            {
                data => <>
                    <div className='card'>
                        <div className="card-body">
                            <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                <h5 className="card-title">{data.location!.name}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{data.location!.address!.address1}</h6>
                            </div>
                            <div className="card-text" style={{ position: 'relative', overflowY: 'auto' }}>
                                {
                                    data.projects!.map(p => <SelectableProjectCard key={p.id} project={p} />)
                                }
                            </div>
                        </div>
                    </div >
                    <ProjectToast />
                </>
            }
        </Await>
    </Suspense>
}

export const ProjectsRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/location/$locationId/projects',
    component: ProjectsScreen,
    loader: i => ({
        data: defer(Client.location.projectDetailsList(i.params))
    })
})