import { useEffect, useRef } from "react";
import { CenterCenter } from "apiary-ui/CenterCenter";
import { Button } from "../../components/Button";
import { CaptureInfo } from "./CaptureToConfirmHandler";

export const Confirm = (props: { captureInfo: CaptureInfo, onCancelled: () => void, onConfirmed: () => void }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            const current = ref.current;
            const canvas = props.captureInfo.canvas;
            canvas.style.width = '100%';
            canvas.style.height = '100%';
            current.appendChild(canvas);
            return () => { current.removeChild(canvas) };

        }
    }, [ref, props.captureInfo.canvas]);
    return <CenterCenter>
        <div style={{ width: '100%', height: '100%' }} ref={ref} />
        <div>
            <Button onClick={props.onCancelled}>Retake</Button>
            <Button onClick={props.onConfirmed}>Upload</Button>
        </div>
    </CenterCenter>
}