import { RootRoute } from "../../Router";
import { useState } from "react";
import { Route } from "@tanstack/react-router";
import { Capture } from "./Capture";
import { Confirm } from "./Confirm";
import { CaptureInfo, CaptureToConfirmHandler } from "./CaptureToConfirmHandler";
import { ConfirmToUploadHandler } from "./UploadHandler";

export const enum ScreenState { capture, confirm }

const AssignmentCaptureScreen = () => {
    const { assignmentId } = AssignmentCaptureRoute.useLoaderData();
    const [screen, setScreenState] = useState<ScreenState>(ScreenState.capture);
    const [captureInfo, setCaptureInfo] = useState<CaptureInfo>();

    switch (screen) {
        case ScreenState.capture:
            return <Capture assignmentId={assignmentId} onCapture={s => CaptureToConfirmHandler(s, setCaptureInfo, setScreenState)} />
        case ScreenState.confirm:
            return <Confirm captureInfo={captureInfo!} onCancelled={() => setScreenState(ScreenState.capture)} onConfirmed={() => ConfirmToUploadHandler(assignmentId, captureInfo!, setScreenState)} />
    }
}

export const AssignmentCaptureRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/assignment/$assignmentId',
    component: AssignmentCaptureScreen,
    loader: ({ params }) => {
        return {
            assignmentId: params.assignmentId
        }
    }
});

