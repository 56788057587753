import axios from 'axios';
import * as client from './types';

export const ApiClient = (onUnauthorized: () => void) => {
  let abortController = new AbortController();

  const customAxios = axios.create({
    baseURL: '/api',
    signal: abortController.signal,
    paramsSerializer: {
      indexes: null
    }
  });

  customAxios.interceptors.response.use(undefined, e => {
    const error = e.response;
    switch (error.status) {
        // @ts-expect-error: Allow case fallthrough
        case 401:
        abortController.abort("Unauthorized");
        abortController = new AbortController();
        customAxios.defaults.signal = abortController.signal;
        onUnauthorized();
      default:
        return Promise.reject(error);
    }
  });

  const c = new client.Api() as (client.Api<unknown> & { setToken: (token: string) => void });
  c.setToken = t => customAxios.defaults.headers.common.Authorization = `Bearer ${t}`
  c.instance = customAxios;

  return c;
};
