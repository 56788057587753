import { faArrowRight, faCamera, faMobileScreenButton, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CenterCenter } from './CenterCenter';
import { LoadingScreen } from './LoadingScreen';
import { Card } from './Card';

export const Icon = FontAwesomeIcon;

export const Icons = {
    Spinner: faSpinner,
    RightArrow: faArrowRight,
    Camera: faCamera,
    MobiePhone: faMobileScreenButton
}

export { CenterCenter, LoadingScreen, Card };