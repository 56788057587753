import { observer } from "mobx-react-lite";
import { ProjectListStore } from "../../../stores/Stores";
import { Icon, Icons } from "apiary-ui";
import { useNavigate } from "@tanstack/react-router";
import { ConfirmProjectsRoute } from "../../../routes/ConfirmProjectsRoute";

const ToastComponent = () => {
    const navigate = useNavigate();
    return <div className="card" style={{ display: ProjectListStore.selectedIds.size ? 'block' : 'none', backgroundColor:'green'}} onClick={() => navigate({to: ConfirmProjectsRoute.to})}>
        <div className='card-body'>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>{`Proceed with ${ProjectListStore.selectedIds.size} projects`}</div>
                <div><Icon icon={Icons.RightArrow} /></div>
            </div>
        </div>
    </div>
}

export const ProjectToast = observer(ToastComponent);