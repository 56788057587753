import { ApiClient } from "apiary-apiclient";
import { ApiaryRouter } from "./Router";
import { SigninRoute } from "./routes/SigninRoute";
import { GetTracer, InitTracer } from "telemetry";

export const Sleep = (ms = 0) => new Promise(r => setTimeout(r, ms));

export const Client = (() => {
    return ApiClient(() => {
        ApiaryRouter.navigate({ to: SigninRoute.to });
    });
})()

InitTracer();
export const Tracer = GetTracer("apiary-app");