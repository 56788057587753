import { Suspense, useEffect, useMemo, useState } from "react";
import { Await } from "@tanstack/react-router";
import { CenterCenter } from "apiary-ui/CenterCenter";
import { VideoDisplay } from "../../components/VideoDisplay";
import { defer } from "@tanstack/react-router";
import { Button } from "../../components/Button";
import { Icon, Icons, LoadingScreen } from "apiary-ui";
import { Client } from "../../Utils";
import { useCamera } from "camera";

export const Capture = (props: { assignmentId: string, onCapture: (stream: MediaStream) => any }) => {
    const cameraPromise = defer(useCamera()!);
    const projectInfo = useMemo(() => defer(Client.assignment.projectList({ assignmentId: props.assignmentId })), []);

    const [orientation, setOrientation] = useState(import.meta.env.DEV == true ? 'portrait-primary' : screen.orientation.type);

    useEffect(() => {
        screen.orientation.onchange = () => setOrientation(screen.orientation.type);
    }, []);

    return <Suspense fallback={<LoadingScreen />}>
        <Await promise={cameraPromise}>{camera => {
            switch (orientation) {
                case 'portrait-primary':
                    return <>
                        <Await promise={projectInfo}>{project =>
                            <h3 style={{ position: 'absolute', top: 0, textAlign: 'center', width: '100svw' }}>{project.description}</h3>
                        }</Await>
                        <CenterCenter>
                            <VideoDisplay stream={camera} />
                        </CenterCenter>
                        <div style={{ position: 'absolute', bottom: '3svh', textAlign: 'center', width: '100svw' }}>
                            <Button onClick={() => props.onCapture(camera)}><Icon icon={Icons.Camera} size="2x" /></Button>
                        </div>
                    </>;
                case 'portrait-secondary':
                case 'landscape-primary':
                case 'landscape-secondary':
                    return <CenterCenter>
                        <h1>Please make sure to hold your phone upright to take a picture</h1>
                        <Icon icon={Icons.MobiePhone} style={{ height: '60svh', width: '60svw' }} />
                    </CenterCenter>
            }

        }
        }</Await>
    </Suspense >
}