import { CenterCenter } from 'apiary-ui/CenterCenter';
import { TimedProgressBar } from '../components/ProgressBar';

export const SplashScreen = (props: { onComplete: () => void }) =>
    <CenterCenter>
        <div style={{ height: '10svh' }} />
        <div style={{ height: '10svh', fontSize: '12vmin', fontWeight: 'bold' }}>
            The Apiary
        </div>
        <div style={{ height: '10svh' }} />
        <img src='/apiary_logo.webp' alt='Apiary Logo' style={{ height: '30svh' }} />
        <div style={{ height: '10svh' }} />
        <div style={{ height: '10svh' }}>
            <TimedProgressBar ms={500} onTimerComplete={props.onComplete} />
        </div>
    </CenterCenter>