/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AddressType {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateProvince?: string | null;
  postalCode?: string | null;
}

export interface AssignmentType {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  projectId?: string;
  project?: ProjectType;
  /** @format uuid */
  userId?: string;
  /** @format date-time */
  expiration?: string;
  /** @format date-time */
  completedAt?: string | null;
}

export interface CoordinateType {
  /** @format double */
  x?: number;
  /** @format double */
  y?: number;
  /** @format double */
  z?: number;
  /** @format double */
  m?: number;
  coordinateValue?: CoordinateType;
  isValid?: boolean;
}

export type CoordinateEqualityComparerType = object;

export interface CoordinateSequenceType {
  /** @format int32 */
  dimension?: number;
  /** @format int32 */
  measures?: number;
  /** @format int32 */
  spatial?: number;
  ordinates?: OrdinatesType;
  hasZ?: boolean;
  hasM?: boolean;
  /** @format int32 */
  zOrdinateIndex?: number;
  /** @format int32 */
  mOrdinateIndex?: number;
  first?: CoordinateType;
  last?: CoordinateType;
  /** @format int32 */
  count?: number;
}

export interface CoordinateSequenceFactoryType {
  ordinates?: OrdinatesType;
}

export enum DimensionType {
  Point = "Point",
  Curve = "Curve",
  Surface = "Surface",
  Collapse = "Collapse",
  Dontcare = "Dontcare",
  True = "True",
  False = "False",
}

export interface EnvelopeType {
  isNull?: boolean;
  /** @format double */
  width?: number;
  /** @format double */
  height?: number;
  /** @format double */
  diameter?: number;
  /** @format double */
  minX?: number;
  /** @format double */
  maxX?: number;
  /** @format double */
  minY?: number;
  /** @format double */
  maxY?: number;
  /** @format double */
  area?: number;
  /** @format double */
  minExtent?: number;
  /** @format double */
  maxExtent?: number;
  centre?: CoordinateType;
}

export interface GeometryType {
  factory?: GeometryFactoryType;
  userData?: any;
  /** @format int32 */
  srid?: number;
  geometryType?: string | null;
  ogcGeometryType?: OgcGeometryTypeType;
  precisionModel?: PrecisionModelType;
  coordinate?: CoordinateType;
  coordinates?: CoordinateType[] | null;
  /** @format int32 */
  numPoints?: number;
  /** @format int32 */
  numGeometries?: number;
  isSimple?: boolean;
  isValid?: boolean;
  isEmpty?: boolean;
  /** @format double */
  area?: number;
  /** @format double */
  length?: number;
  centroid?: PointType;
  interiorPoint?: PointType;
  pointOnSurface?: PointType;
  dimension?: DimensionType;
  boundary?: GeometryType;
  boundaryDimension?: DimensionType;
  envelope?: GeometryType;
  envelopeInternal?: EnvelopeType;
  isRectangle?: boolean;
}

export interface GeometryFactoryType {
  precisionModel?: PrecisionModelType;
  coordinateSequenceFactory?: CoordinateSequenceFactoryType;
  /** @format int32 */
  srid?: number;
  geometryServices?: NtsGeometryServicesType;
}

export type GeometryOverlayType = object;

export interface LocationType {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  name?: string | null;
  coordinate?: PointType;
  address?: AddressType;
}

export interface LocationProjectDetailsType {
  location?: LocationType;
  projects?: ProjectType[] | null;
}

export interface LocationProjectSummaryType {
  location?: LocationType;
  /** @format double */
  distance?: number;
  /** @format int32 */
  projectCount?: number;
}

export interface NtsGeometryServicesType {
  geometryOverlay?: GeometryOverlayType;
  coordinateEqualityComparer?: CoordinateEqualityComparerType;
  /** @format int32 */
  defaultSRID?: number;
  defaultCoordinateSequenceFactory?: CoordinateSequenceFactoryType;
  defaultPrecisionModel?: PrecisionModelType;
}

export enum OgcGeometryTypeType {
  Point = "Point",
  LineString = "LineString",
  Polygon = "Polygon",
  MultiPoint = "MultiPoint",
  MultiLineString = "MultiLineString",
  MultiPolygon = "MultiPolygon",
  GeometryCollection = "GeometryCollection",
  CircularString = "CircularString",
  CompoundCurve = "CompoundCurve",
  CurvePolygon = "CurvePolygon",
  MultiCurve = "MultiCurve",
  MultiSurface = "MultiSurface",
  Curve = "Curve",
  Surface = "Surface",
  PolyhedralSurface = "PolyhedralSurface",
  TIN = "TIN",
}

export enum OperatingSystemType {
  Ios = "ios",
  Android = "android",
  Windows = "windows",
  Mac = "mac",
  Unknown = "unknown",
}

export enum OrdinatesType {
  None = "None",
  X = "X",
  Y = "Y",
  XY = "XY",
  Z = "Z",
  XYZ = "XYZ",
  Spatial4 = "Spatial4",
  Spatial5 = "Spatial5",
  Spatial6 = "Spatial6",
  Spatial7 = "Spatial7",
  Spatial8 = "Spatial8",
  Spatial9 = "Spatial9",
  Spatial10 = "Spatial10",
  Spatial11 = "Spatial11",
  Spatial12 = "Spatial12",
  Spatial13 = "Spatial13",
  Spatial14 = "Spatial14",
  Spatial15 = "Spatial15",
  Spatial16 = "Spatial16",
  AllSpatialOrdinates = "AllSpatialOrdinates",
  M = "M",
  XYM = "XYM",
  XYZM = "XYZM",
  Measure2 = "Measure2",
  Measure3 = "Measure3",
  Measure4 = "Measure4",
  Measure5 = "Measure5",
  Measure6 = "Measure6",
  Measure7 = "Measure7",
  Measure8 = "Measure8",
  Measure9 = "Measure9",
  Measure10 = "Measure10",
  Measure11 = "Measure11",
  Measure12 = "Measure12",
  Measure13 = "Measure13",
  Measure14 = "Measure14",
  Measure15 = "Measure15",
  Measure16 = "Measure16",
  AllMeasureOrdinates = "AllMeasureOrdinates",
  AllOrdinates = "AllOrdinates",
}

export enum PlatformType {
  Web = "web",
  Ios = "ios",
  Android = "android",
}

export interface PointType {
  factory?: GeometryFactoryType;
  userData?: any;
  /** @format int32 */
  srid?: number;
  precisionModel?: PrecisionModelType;
  /** @format int32 */
  numGeometries?: number;
  isSimple?: boolean;
  isValid?: boolean;
  /** @format double */
  area?: number;
  /** @format double */
  length?: number;
  centroid?: PointType;
  interiorPoint?: PointType;
  pointOnSurface?: PointType;
  envelope?: GeometryType;
  envelopeInternal?: EnvelopeType;
  isRectangle?: boolean;
  coordinateSequence?: CoordinateSequenceType;
  coordinates?: CoordinateType[] | null;
  /** @format int32 */
  numPoints?: number;
  isEmpty?: boolean;
  dimension?: DimensionType;
  boundaryDimension?: DimensionType;
  /** @format double */
  x?: number;
  /** @format double */
  y?: number;
  coordinate?: CoordinateType;
  geometryType?: string | null;
  ogcGeometryType?: OgcGeometryTypeType;
  boundary?: GeometryType;
  /** @format double */
  z?: number;
  /** @format double */
  m?: number;
}

export interface PrecisionModelType {
  isFloating?: boolean;
  /** @format int32 */
  maximumSignificantDigits?: number;
  /** @format double */
  scale?: number;
  /** @format double */
  gridSize?: number;
  precisionModelType?: PrecisionModelsType;
}

export enum PrecisionModelsType {
  Floating = "Floating",
  FloatingSingle = "FloatingSingle",
  Fixed = "Fixed",
}

export interface ProjectType {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  description?: string | null;
  location?: LocationType;
}

export interface RequestSubmissionResultType {
  /** @format uuid */
  submissionId?: string;
  submissionUrls?: UrlSetType;
}

export interface SubmissionType {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  assignmentId?: string;
  assignment?: AssignmentType;
  /** @format date-time */
  clientTimestamp?: string | null;
  /** @format date-time */
  submittedAt?: string | null;
  geoInfo?: SubmissionGeoInfoType;
  deviceInfo?: SubmissionDeviceInfoType;
  urls?: UrlSetType;
}

export interface SubmissionCompletionInfoType {
  geoInfo?: SubmissionGeoInfoType;
  deviceInfo?: SubmissionDeviceInfoType;
  /** @format date-time */
  clientTimestamp?: string;
}

export interface SubmissionDeviceInfoType {
  /** @format uuid */
  id?: string;
  deviceId?: string | null;
  model?: string | null;
  platform?: PlatformType;
  os?: OperatingSystemType;
  osVersion?: string | null;
  manufacturer?: string | null;
  webView?: string | null;
}

export interface SubmissionGeoInfoType {
  /** @format uuid */
  id?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  /** @format double */
  accuracy?: number;
}

export interface UrlSetType {
  full?: string | null;
  thumbnail?: string | null;
  json?: string | null;
}

export interface UserResultType {
  token?: string | null;
  givenName?: string | null;
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title apiary-backend
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  assignment = {
    /**
     * No description
     *
     * @tags Assignment
     * @name ProjectList
     * @request GET:/Assignment/project
     * @secure
     */
    projectList: (
      query?: {
        /** @format uuid */
        assignmentId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectType, any>({
        path: `/Assignment/project`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name SignInGoogleList
     * @request GET:/Auth/sign-in/google
     * @secure
     */
    signInGoogleList: (
      query?: {
        credential?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResultType, any>({
        path: `/Auth/sign-in/google`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  home = {
    /**
     * No description
     *
     * @tags Home
     * @name HomeList
     * @request GET:/Home
     * @secure
     */
    homeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/Home`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  location = {
    /**
     * No description
     *
     * @tags Location
     * @name AvailableProjectsList
     * @request GET:/Location/available-projects
     * @secure
     */
    availableProjectsList: (
      query?: {
        /** @format double */
        latitude?: number;
        /** @format double */
        longitude?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LocationProjectSummaryType[], any>({
        path: `/Location/available-projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Location
     * @name ProjectDetailsList
     * @request GET:/Location/project-details
     * @secure
     */
    projectDetailsList: (
      query?: {
        /** @format uuid */
        locationId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LocationProjectDetailsType, any>({
        path: `/Location/project-details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  project = {
    /**
     * No description
     *
     * @tags Project
     * @name AvailableProjectsList
     * @request GET:/Project/available-projects
     * @secure
     */
    availableProjectsList: (
      query?: {
        projectIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ProjectType[], any>({
        path: `/Project/available-projects`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name IncompletedAssignmentsList
     * @request GET:/Project/incompleted-assignments
     * @secure
     */
    incompletedAssignmentsList: (params: RequestParams = {}) =>
      this.request<AssignmentType[], any>({
        path: `/Project/incompleted-assignments`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name RequestAssignmentsCreate
     * @request POST:/Project/request-assignments
     * @secure
     */
    requestAssignmentsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Project/request-assignments`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name RequestSubmissionCreate
     * @request POST:/Project/request-submission
     * @secure
     */
    requestSubmissionCreate: (
      query?: {
        /** @format uuid */
        assignmentId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RequestSubmissionResultType, any>({
        path: `/Project/request-submission`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name SubmissionCompleteCreate
     * @request POST:/Project/submission-complete
     * @secure
     */
    submissionCompleteCreate: (
      data: SubmissionCompletionInfoType,
      query?: {
        /** @format uuid */
        submissionId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/Project/submission-complete`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name GetProjectsList
     * @request GET:/Project/get-projects
     * @secure
     */
    getProjectsList: (params: RequestParams = {}) =>
      this.request<ProjectType[], any>({
        path: `/Project/get-projects`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name GetSubmissionsList
     * @request GET:/Project/get-submissions
     * @secure
     */
    getSubmissionsList: (
      query?: {
        /** @format uuid */
        projectId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubmissionType[], any>({
        path: `/Project/get-submissions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
