import { Await, Route, defer, useNavigate } from "@tanstack/react-router"
import { RootRoute } from "../Router"
import { Client } from "../Utils"
import { ProjectListStore } from "../stores/Stores"
import { Suspense } from "react"
import { ProjectCard } from "../components/ui/Projects/ProjectCard"
import { Icon, Icons, LoadingScreen } from "apiary-ui"
import { AssignProjectRoute } from "./AssignProjectRoute"

const ConfirmProjectsScreen = () => {
    const { data } = ConfirmProjectsRoute.useLoaderData();
    const navigate = useNavigate();
    
    return <Suspense fallback={<LoadingScreen />}>
        <Await promise={data}>{
            data =>
                <>
                    <div>
                        <div className='card'>
                            <div className="card-body">
                                <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}>
                                    <h5 className="card-title">Confirm Projects</h5>
                                </div>
                                <div className="card-text" style={{ position: 'relative', overflowY: 'auto' }}>
                                    {
                                        data.map(p => <ProjectCard key={p.id} project={p} />)
                                    }
                                </div>
                            </div>
                        </div >
                    </div>
                    <div className="card" style={{ backgroundColor: 'green' }} onClick={() => navigate({ to: AssignProjectRoute.to })}>
                        <div className='card-body'>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%' }}>Confirm!</div>
                                <div><Icon icon={Icons.RightArrow} /></div>
                            </div>
                        </div>
                    </div>
                </>
        }</Await>
    </Suspense>
}

export const ConfirmProjectsRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/projects/confirm',
    component: ConfirmProjectsScreen,
    loader: () => ({
        data: defer(Client.project.availableProjectsList({ projectIds: Array.from(ProjectListStore.selectedIds) }))
    })
})