import { Await, Navigate, Route, defer } from "@tanstack/react-router";
import { Suspense } from "react";
import { LoadingScreen } from "apiary-ui";
import { RootRoute } from "../Router";
import { Client } from "../Utils";
import { ProjectListStore } from "../stores/Stores";
import { AssignmentDashboardRoute } from "./AssignmentDashboardRoute";

const AssignProjectScreen = () => {
    const { data } = AssignProjectRoute.useLoaderData();

    return <Suspense fallback={<LoadingScreen />}>
        <Await promise={data}>
            {() => <Navigate to={AssignmentDashboardRoute.to} />}
        </Await>
    </Suspense>
}

const loadData = async () => {
    await Client.project.requestAssignmentsCreate(Array.from(ProjectListStore.selectedIds));
    ProjectListStore.clear();
}

export const AssignProjectRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/project/assign',
    component: AssignProjectScreen,
    loader: () => ({
        data: defer(loadData())
    })
})