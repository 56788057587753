import { Route, useNavigate } from "@tanstack/react-router";
import { GoogleSignin } from "../components/GoogleSignin";
import { RootRoute } from "../Router";
import { CenterCenter } from "apiary-ui/CenterCenter";
import { AuthStore } from "../stores/Stores";
import { UserResultType } from "apiary-apiclient/types";
import { useState } from "react";
import { Icon, Icons } from "apiary-ui";
import { MainRoute } from "./MainRoute";

const SigninScreen = () => {
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();

    const signInHandler = async (userPromise: Promise<UserResultType>) => {
        setProcessing(true);
        await AuthStore.setAuth(userPromise);
        navigate({ to: MainRoute.to });
    }

    return <div className='card'>
        <div className="card-body">
            <div>
                <h5 className="card-title">Sign into The Apiary</h5>
            </div>
            <div className="card-text">
                <CenterCenter>
                    {processing ?
                        <Icon icon={Icons.Spinner} spin={true} size={'6x'} /> :
                        <GoogleSignin signInHandler={signInHandler} />
                    }
                </CenterCenter>
            </div>
        </div>
    </div>
}


export const SigninRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/signin',
    component: SigninScreen,

});
