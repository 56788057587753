import { Suspense } from "react";
import { Client } from "../Utils";
import { LocationCard } from "../components/ui/Locations/LocationCard";
import { RootRoute } from "../Router";
import { Await, Route, defer } from "@tanstack/react-router";
import { LoadingScreen } from "apiary-ui";
import { Geolocation } from "../components/Geolocation";

const LocationsScreen = () => {
    const { data } = LocationsRoute.useLoaderData();
    return <Suspense fallback={<LoadingScreen />}>
        <Await promise={data}>{p =>
            p.length > 0 ?
                <>{p.map((v, i) => <LocationCard key={i} locationProject={v} />)}</> :
                <h1>There are no available projects.</h1>
        }</Await>
    </Suspense>
}

export const LocationsRoute = new Route({
    getParentRoute: () => RootRoute,
    path: 'location',
    component: LocationsScreen,
    loader: () => {
        const dataLoader = async () => {
            const position = await Geolocation.getCurrentPosition();
            const result = await Client.location.availableProjectsList({ latitude: position.coords.latitude, longitude: position.coords.longitude });
            return result;
        }
        return {
            data: defer(dataLoader())
        }}
});
