import { Dispatch } from "react";
import { ScreenState } from "./AssignmentCaptureRoute";
import { SubmissionCompletionInfoType } from "apiary-apiclient/types";
import { DeviceStore } from "../../stores/Stores";
import { Geolocation } from '../../components/Geolocation';
import { Tracer } from "../../Utils";
import { Camera } from "camera";

export type CaptureInfo = { canvas: HTMLCanvasElement, metadataPromise: Promise<SubmissionCompletionInfoType> };

export const CaptureToConfirmHandler = async (stream: MediaStream, setCaptureInfo: Dispatch<CaptureInfo>, setState: Dispatch<ScreenState>) => {
    const captureInfo = {
        canvas: await Camera.Capture(stream),
        metadataPromise: generateMetadata(stream.getVideoTracks()[0].getSettings())
    }
    setCaptureInfo(captureInfo);
    setState(ScreenState.confirm);
}

const generateMetadata = (settings: MediaTrackSettings) =>
    Tracer.AsyncSpan('Loading metadata', async () => {
        const geoInfo = async () => {
            const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 30 * 1000 });
            return {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy
            }
        }

        return {
            clientTimestamp: (new Date()).toISOString(),
            geoInfo: await geoInfo(),
            deviceInfo: await DeviceStore,
            imageInfo: {
                imageType: 'image/jpeg',
                height: settings.height,
                width: settings.width
            }
        }
    });