import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Client } from "../Utils";
import { UserResultType } from "apiary-apiclient/types";

export const GoogleSignin = (props: { signInHandler: (user: Promise<UserResultType>) => void }) => {
    const signIn = async (i: any) => {
        const userLogin = Client.auth.signInGoogleList({ credential: i.credential });
        props.signInHandler(userLogin);
    }

    return <GoogleOAuthProvider clientId="148818018426-6v01sgi0flu4lkpo6lej6e767nop6ull.apps.googleusercontent.com">
        <GoogleLogin onSuccess={signIn} onError={console.error} />
    </GoogleOAuthProvider>
}