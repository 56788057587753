import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import './scss/styles.scss'
//import * as bootstrap from 'bootstrap';
import { ApiaryComponent } from './Router';
import { SplashScreen } from './screens/SplashScreen';
import { StoreInit } from './stores/Stores';
import { uploadListStore } from './routes/AssignmentCapture/UploadHandler';
import { observer } from 'mobx-react-lite';

/*
import { configure } from "mobx"
configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true
})
*/
const [splashComplete, splashPromise] = (() => {
  let complete = () => { };
  const promise = new Promise<void>(r => complete = r);
  return [complete, promise];
})();

const Initializer = Promise.all([splashPromise, StoreInit()]);

const UploadProgressComponent = observer(() => [...uploadListStore.uploads.entries()].map((v, i) => <div key={i}>{v[0]} - {v[1].toFixed(2)}%</div>));

const MainApp = () => {
  const [hasLoaded, setLoaded] = useState(false);

  Initializer.then(() => setLoaded(true));

  return hasLoaded ?
    <React.StrictMode>
      <ApiaryComponent />
      <div style={{ position: 'absolute', bottom: 0, zIndex: -1 }}>
        <UploadProgressComponent />
        <div>{BUILD_NUMBER}</div>
      </div>
    </React.StrictMode> :
    <SplashScreen onComplete={splashComplete} />
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <MainApp />);