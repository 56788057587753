import { makeAutoObservable } from "mobx";
import { Client } from "../Utils";
import { Preferences } from "@capacitor/preferences";
import { OperatingSystemType, PlatformType, UserResultType } from "apiary-apiclient/types";

const AuthStoreKey = 'AuthStoreKey';

export const DeviceStore = (async () => {
    const loaderPromise = await import('@capacitor/device');
    const deviceIdPromise = loaderPromise.Device.getId();
    const deviceInfoPromise = loaderPromise.Device.getInfo();
    const [deviceId, deviceInfo] = await Promise.all([deviceIdPromise, deviceInfoPromise]);
    return {
        deviceId: deviceId.identifier,
        model: deviceInfo.model,
        platform: deviceInfo.platform as PlatformType,
        os: deviceInfo.operatingSystem as OperatingSystemType,
        osVersion: deviceInfo.osVersion,
        manufacturer: deviceInfo.manufacturer,
        webView: deviceInfo.webViewVersion ?? ''
    }
})();

export const ProjectListStore = makeAutoObservable({
    selectedIds: new Set<string>(),
    add(id: string) { this.selectedIds.add(id); },
    remove(id: string) { this.selectedIds.delete(id); },
    clear() { this.selectedIds = new Set<string>() }
});

export const AuthStore = makeAutoObservable({
    token: null as (string | null),
    name: null as (string | null),
    setAuth: async (userPromise: Promise<UserResultType | undefined>) => {
        const user = (await userPromise) ?? {};
        AuthStore.name = user.givenName!;
        AuthStore.token = user.token!
        Client.setToken(AuthStore.token);
        await Preferences.set({
            key: AuthStoreKey,
            value: JSON.stringify(AuthStore)
        });
    }
});

export const StoreInit = async () => {
    const authLoader = async () => {
        const authJson = await Preferences.get({ key: AuthStoreKey });
        return JSON.parse(authJson.value!) as UserResultType;
    }
    AuthStore.setAuth(authLoader());
}
