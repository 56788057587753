import { useEffect, useRef } from "react";

export const VideoDisplay = (props: { stream: MediaStream }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current && props.stream) {
            const vidRef = videoRef.current;
            vidRef.onloadedmetadata = vidRef.play;
            vidRef.srcObject = props.stream;
        }
    }, [props.stream]);

    return <video ref={videoRef} style={{ maxWidth: '100%' }} playsInline={true} controls={false} />
}