import { Route } from "@tanstack/react-router";
import { RootRoute } from "../Router";
import {  PromiseProgressBar } from "../components/ProgressBar";
import { Sleep } from "../Utils";


const TestScreen = () => {
    return <>
        <h1>TEST</h1>
        <PromiseProgressBar ms={10000} promise={Sleep(5000)} />
    </>
}

export const TestRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/test',
    component: TestScreen,

});
