import { Dispatch } from "react";
import { ScreenState } from "./AssignmentCaptureRoute";
import { Client, Tracer } from "../../Utils";
import axios from "axios";
import { CaptureInfo } from "./CaptureToConfirmHandler";
import { Camera } from "camera";
import { makeAutoObservable } from "mobx";

export const uploadListStore = makeAutoObservable({
    uploads: new Map<string, number>(),
    set(key: string, percent: number) { this.uploads.set(key, percent) },
    remove(key: string) { this.uploads.delete(key); }
});

let NetworkQueue = Promise.resolve<any>(null);
let ProcessingQueue = Promise.resolve<any>(null);

export const ConfirmToUploadHandler = async (assignmentId: string, captureInfo: CaptureInfo, setState: Dispatch<ScreenState>) => {
    const canvas = captureInfo.canvas;

    const Go = (setUploadPercentage: (percent: number) => void) => {
        const azureClient = axios.create({
            headers: {
                "content-type": 'image/jpg',
                "x-ms-blob-type": 'BlockBlob'
            }
        });

        const segments = generateUpdateCallbacks([.05, .20, .05, .30, .35, .05], setUploadPercentage);
        const [subCreate, thmbEncode, thmbUpload, fullEncode, fullUpload, subComplete] = [...segments];

        return Tracer.AsyncSpan("Submission", async () => {
            const SubmissionCreate = (async () => {
//                await NetworkQueue;
                return Tracer.AsyncSpan("Submission Create",
                    () => segment(Client.project.requestSubmissionCreate({ assignmentId }), subCreate));
            })()
//            NetworkQueue = SubmissionCreate;

            const ThumbnailResizeAndEncode = (async () => {
                await ProcessingQueue;
                const height = 300;
                const width = Math.round((height / canvas.height) * canvas.width);
                return await Tracer.AsyncSpan("Thumbnail Resize & Encode",
                    () => segment(Camera.ResizeAndEncode(canvas, width, height), thmbEncode));
            })()
            ProcessingQueue = ThumbnailResizeAndEncode;

            const ThumbnailUpload = (async () => {
                const [submissionCreate, thumbnailRE] = await Promise.all([SubmissionCreate, ThumbnailResizeAndEncode, NetworkQueue]);
                await Tracer.AsyncSpan("Thumbnail Upload",
                    () => azureClient.put(submissionCreate.submissionUrls!.thumbnail!, thumbnailRE, {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            thmbUpload(loaded / total!);
                        }
                    })
                );
            })()
            NetworkQueue = ThumbnailUpload;

            const FullImageEncode = (async () => {
                await ProcessingQueue;
                return await Tracer.AsyncSpan("Full Image Encode",
                    () => segment(Camera.EncodeCanvas(canvas), fullEncode));
            })();
            ProcessingQueue = FullImageEncode;

            const FullImageUpload = (async () => {
                const [submissionCreate, fullE] = await Promise.all([SubmissionCreate, FullImageEncode, NetworkQueue]);
                await Tracer.AsyncSpan("Full Image Upload",
                    () => azureClient.put(submissionCreate.submissionUrls!.full!, fullE, {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            fullUpload(loaded / total!);
                        }
                    })
                );
            })()
            NetworkQueue = FullImageUpload;

            const SubmissionComplete = (async () => {
                const [metadata, submission] = await Promise.all([captureInfo.metadataPromise, SubmissionCreate, ThumbnailUpload, FullImageUpload, NetworkQueue])
                await Tracer.AsyncSpan("Submission Complete",
                    () => segment(Client.project.submissionCompleteCreate(
                        metadata, {
                        submissionId: submission.submissionId
                    }), subComplete)
                );
            })()
//            NetworkQueue = SubmissionComplete;

            await SubmissionComplete;
        });
    }

    setState(ScreenState.capture);
    const key = new Date().toISOString();
    uploadListStore.set(key, 0)
    await Go(p => uploadListStore.set(key, p));
    requestAnimationFrame(() => uploadListStore.remove(key));
}

const generateUpdateCallbacks = (segments: number[], setPercentage: (progress: number) => any) => {
    if (segments.reduce((t, a) => t + a) != 1)
        throw "Segment sizes don't add up to 1";

    const segmentPercentages = segments.map(() => 0);

    const callbackGenerator = segmentPercentages.map((_v, i) =>
        (progress: number) => {
            segmentPercentages[i] = segments[i] * progress;
            const percent = segmentPercentages.reduce((a, b) => a + b) * 100;
            setPercentage(percent);
        });

    return callbackGenerator;
}

const segment = async <T>(promise: Promise<T>, progressCallback: (progress: number) => any) => {
    const finish = animateProgressEasing(progressCallback)
    const result = await promise;
    finish();
    return result;
}

const animateProgressEasing = (progressCallback: (progress: number) => undefined, timeout = 30000) => {
    const calculator = (elapsed: number) => Math.pow(Math.log(elapsed), 4);
    const finalBasis = calculator(timeout);
    const startTime = Date.now();

    let keepGoing = true;

    const step = () => {
        const delta = Date.now() - startTime!;

        if (delta > timeout)
            throw "Timed out";

        if (keepGoing) {
            const result = calculator(delta) / finalBasis;
            progressCallback(result);
            requestAnimationFrame(step);
        }
        else
            progressCallback(1);
    };

    requestAnimationFrame(step);
    return () => keepGoing = false
}