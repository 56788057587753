import { ProjectType } from "apiary-apiclient/types";
import { useEffect, useState } from "react";
import { ProjectListStore } from "../../../stores/Stores";

export const SelectableProjectCard = (props: { project: ProjectType }) => {
    const [selected, setSelected] = useState(false);
    const project = props.project;

    useEffect(() => {
        if (selected)
            ProjectListStore.add(project.id!);
        else
            ProjectListStore.remove(project.id!);

    }, [selected, project.id]);

    return <div onClick={() => setSelected(!selected)} style={{ backgroundColor: (selected ? 'yellow' : 'transparent') }}>
        <ProjectCard project={project} />
    </div>
}

export const ProjectCard = (props: { project: ProjectType }) => <div className='card' style={{ backgroundColor: 'transparent' }}>
    <div className='card-body'>
        <p className="card-text">
            {props.project.description}
        </p>
    </div>
</div>