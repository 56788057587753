import { useEffect, useMemo, useState } from "react";

export const ProgressBar = (props: { percentage: number }) =>
    <div style={{ width: '100%', height: '100%' }}>
        <div style={{ width: `${props.percentage}%`, height: '100%', minHeight: '1rem', backgroundColor: 'black' }} />
    </div>

export const TimedProgressBar = (props: { ms: number, onTimerComplete?: () => void }) => {
    const [percent, setPercent] = useState(0);
    const onComplete = props.onTimerComplete ? props.onTimerComplete : () => { };
    const startTime = useMemo(() => Date.now(), []);

    useEffect(() => {
        const id = requestAnimationFrame(() => {
            const now = Date.now();
            const delta = now - startTime;

            if (delta < props.ms) {
                setPercent((delta * 100) / props.ms);
            }
            else {
                onComplete();
                setPercent(100);
            }
        });
        return () => cancelAnimationFrame(id);
    }, [percent]);

    return <ProgressBar percentage={percent} />
}

export const PromiseProgressBar = (props: { ms: number, promise: Promise<any> }) => {
    const [isComplete, setComplete] = useState(false);

    useEffect(() => {
        const promiseComplete = async () => {
            await props.promise;
            setComplete(true);
        }
        promiseComplete();

    }, [])
    return isComplete ?
        <ProgressBar percentage={100} /> :
        <TimedProgressBar ms={props.ms} />

}