import { useNavigate } from '@tanstack/react-router'
import { LocationProjectSummaryType } from 'apiary-apiclient/types'
import { ProjectsRoute } from '../../../routes/ProjectsRoute';

export const LocationCard = (props: { locationProject: LocationProjectSummaryType }) => {
    const navigate = useNavigate();
    const lp = props.locationProject;
    
    return <div className='card' onClick={() => navigate({ to: ProjectsRoute.to, params: { locationId: lp.location!.id! } })}>
        <div className="card-body">
            <h5 className="card-title">{lp.location?.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted">
                {lp.location?.address?.address1}
                -
                {(lp.distance! * 69.0946074).toLocaleString(undefined, { maximumFractionDigits: 1 })} miles
            </h6>
            <p className="card-text">
                {lp.projectCount} projects
            </p>
        </div>
    </div >
}