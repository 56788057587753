import { Await, Route, defer, useNavigate } from "@tanstack/react-router";
import { Suspense } from "react";
import { LoadingScreen } from "apiary-ui";
import { RootRoute } from "../Router";
import { Client } from "../Utils";
import { ProjectCard } from "../components/ui/Projects/ProjectCard";
import { AssignmentCaptureRoute } from "./AssignmentCapture/AssignmentCaptureRoute";

const AssignmentDashboardScreen = () => {
    const { data } = AssignmentDashboardRoute.useLoaderData();
    const navigate = useNavigate();

    return <Suspense fallback={<LoadingScreen />}>
        <Await promise={data}>{
            data => <div className='card'>
                <div className="card-body">
                    <div>
                        <h5 className="card-title">Incomplete Assignments</h5>
                    </div>
                    <div className="card-text">
                        {
                            data.map(a =>
                                <div key={a.projectId} onClick={() => navigate({ to: AssignmentCaptureRoute.to, params: { assignmentId: a.id! } })}>
                                    <ProjectCard project={a.project!} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div >}</Await>
    </Suspense>
}

export const AssignmentDashboardRoute = new Route({
    getParentRoute: () => RootRoute,
    path: '/assignment/dashboard',
    component: AssignmentDashboardScreen,
    loader: () => ({
        data: defer(Client.project.incompletedAssignmentsList())
    })
})