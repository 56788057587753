/*
window['dataLayer'] = (window['dataLayer'] || []) as any[];
function gtag(...args: any) { window['dataLayer'].push(args); }
gtag('js', new Date());
gtag('config', 'G-N9DFL8ZQ64')
*/

// const script = document.createElement('script');
// script.async = true;
// script.defer = true;
// script.src = 'https://www.googletagmanager.com/gtag/js?id=G-N9DFL8ZQ64';

import {  ConsoleSpanExporter, SimpleSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import opentelemetry, { Tracer } from '@opentelemetry/api';

const GeneratePublicTracer = (tracer: Tracer) => ({
    AsyncSpan: <T>(name: string, action: () => Promise<T>): Promise<T> =>
        tracer.startActiveSpan(name, async span => {
            const result = await action();
            span.end();
            return result;
        })
})

export const GetTracer = (label: string) => GeneratePublicTracer(opentelemetry.trace.getTracer(label));

export const InitTracer = () => {
    const provider = new WebTracerProvider();
    provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
    // provider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter({
    //     url: 'https://otlp.nr-data.net:443/v1/traces',
    //     headers: {
    //         'api-key': 'fada73f9200840ea2323114acc539ce1FFFFNRAL'
    //     }
    // })));

    provider.register({
        contextManager: new ZoneContextManager()
    });

    registerInstrumentations({
        instrumentations: [
            //            new DocumentLoadInstrumex`ntation(),
            new XMLHttpRequestInstrumentation()
        ],
    });
}