import { useEffect, useState } from "react";

const streamConfig = {
  audio: false,
  video: {
    facingMode: 'environment',
    height: { ideal: 32768 },
    width: { ideal: 24576 },
  }
}

export default () => {
  const [streamPromise, setStreamPromise] = useState<Promise<MediaStream>>();

  useEffect(() => {
    if (streamPromise) {
      return () => {
        (async () => (await streamPromise).getTracks().forEach(t => t.stop()))();
      }
    }
    else
      setStreamPromise(navigator.mediaDevices.getUserMedia(streamConfig));

  }, [streamPromise]);

  return streamPromise ?? new Promise(() => { });
}