import * as Comlink from 'comlink';
import { EncodeType } from "./Encode";
import { ResizeType } from "./Resize";
import uc from './useCamera';

const resizeWorker = (() => {
    const worker = new Worker(new URL('./Resize', import.meta.url), { type: 'module' });
    return Comlink.wrap<ResizeType>(worker);
})();

const encodeWorker = (() => {
    const worker = new Worker(new URL('./Encode', import.meta.url), { type: 'module' });
    return Comlink.wrap<EncodeType>(worker);
})();

const encode = (data: Uint8ClampedArray, width: number, height: number) =>
    encodeWorker(Comlink.transfer(data, [data.buffer]), width, height);

export const useCamera = uc;
export const Camera = {
    Capture: async (stream: MediaStream) => await (await import('./Capture')).default(stream),
    ResizeAndEncode: async (canvas: HTMLCanvasElement, width: number, height: number) => {
        const context = canvas.getContext('2d');
        const data = new Uint8Array(context!.getImageData(0, 0, canvas.width, canvas.height).data);
        const resizedData = await resizeWorker(Comlink.transfer(data, [data.buffer]),
            canvas.width, canvas.height,
            width, height);
        return await encode(resizedData, width, height);
    },
    EncodeCanvas: (canvas: (HTMLCanvasElement | OffscreenCanvas)) => {
        const context = canvas.getContext('2d', { willReadFrequently: true });
        const data = context!.getImageData(0, 0, canvas.width, canvas.height).data;
        return encode(data, canvas.width, canvas.height);
    }
}

